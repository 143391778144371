import React from "react"
import { Link } from "gatsby"

const ArticleNav = ({ title }) => (
  <nav>
    <div className={"articles-nav-left"}>
      <Link to={"/"} className={"articles-logo"}>Mark Beeson</Link>
      <span className={"nav-breadcrumb"}>: //</span>
      <Link to={"/articles/"} className={"nav-path"}>Articles</Link>
      {title &&
      <span className={"nav-breadcrumb"}>/</span>
      }
    </div>
    {title &&
    <div className={"nav-article-title"}>{title}</div>
    }
    <div className={"articles-nav-right"}>
      <Link to={"/"}>About Mark</Link>
      <a href={"mailto:mark@beeson.email"}>Email</a>
    </div>
  </nav>
)

export default ArticleNav
