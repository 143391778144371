import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import ArticleNav from "../components/nav"

const ArticlePage = ({ data }) => (
  <div className="page-article">
    <SEO title={data.markdownRemark.frontmatter.title} keywords={[`mark`, `beeson`, `engineering`]}
         description={data.markdownRemark.excerpt}/>
    <ArticleNav title={data.markdownRemark.frontmatter.title}/>

    <article>
      <h1 className={"article-title"}>{data.markdownRemark.frontmatter.title}</h1>
      <h3 className={"article-subhead"}>{data.markdownRemark.frontmatter.subhead}</h3>
      <div className={"article-timetoread"}>Minutes to read &#x2192; {data.markdownRemark.timeToRead}</div>
      <Img fluid={data.markdownRemark.frontmatter.cover.childImageSharp.fluid}/>
      <div className={"covercaption"}>{data.markdownRemark.frontmatter.covercaption}</div>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}/>
    </article>

    <section className={"other-articles"}>
      <div className={"oldernewer article-list"}>
        <div>
          {data.newerpost.edges.length === 0 &&
          <div className={"article-list-article blank"}>&nbsp;</div>
          }
          {data.newerpost.edges.filter(el => el.node.frontmatter.title.length > 0)
            .map(({ node }) => {
              return (
                <Link to={`/articles${node.fields.slug}`} key={node.id} className={"article-list-article"}>
                  <div className={"article-nav-newer"}>&#x2190; Newer</div>
                  <div className={"article-list-image"}
                       style={{ backgroundImage: `url(${node.frontmatter.cover.childImageSharp.fluid.src})` }}/>
                  <h2>{node.frontmatter.title}</h2>
                  <h4 className={"no-margin"}>{node.frontmatter.subhead}</h4>
                  <span>Minutes to read &#x2192; {node.timeToRead}</span>
                </Link>
              )
            })
          }
          {data.olderpost.edges.filter(el => el.node.frontmatter.title.length > 0)
            .map(({ node }) => {
              return (
                <Link to={`/articles${node.fields.slug}`} key={node.id} className={"article-list-article"}>
                  <div className={"article-nav-older"}>Older &#x2192;</div>
                  <div className={"article-list-image"}
                       style={{ backgroundImage: `url(${node.frontmatter.cover.childImageSharp.fluid.src})` }}/>
                  <h2>{node.frontmatter.title}</h2>
                  <h4 className={"no-margin"}>{node.frontmatter.subhead}</h4>
                  <span>Minutes to read &#x2192; {node.timeToRead}</span>
                </Link>
              )
            })
          }
        </div>
      </div>
      <h4 className={"latestarticles-label"}>Latest Articles</h4>
      <div className={"latestarticles article-list no-bottom"}>
        <div>
          {data.latestposts.edges.filter(el => el.node.frontmatter.title.length > 0)
            .map(({ node }) => {
              return (
                <Link to={`/articles${node.fields.slug}`} key={node.id} className={"article-list-article"}>
                  <div className={"article-list-image"}
                       style={{ backgroundImage: `url(${node.frontmatter.cover.childImageSharp.fluid.src})` }}/>
                  <h2>{node.frontmatter.title}</h2>
                  <h4 className={"no-margin"}>{node.frontmatter.subhead}</h4>
                  <span>Minutes to read &#x2192; {node.timeToRead}</span>
                </Link>
              )
            })
          }
        </div>
      </div>
    </section>

    <footer>
      <div>
        <div className="contact">Contact &#x2192;</div>
        <a href="https://twitter.com/markrbeeson" className="action-link">Twitter</a>
        <a href="https://linkedin.com/in/mark242/" className="action-link">LinkedIn</a>
        <a href="https://github.com/m242" className="action-link">GitHub</a>
        <a href="https://gitlab.com/markbeeson" className="action-link">GitLab</a>
        <a href="mailto:mark@beeson.email" className="action-link">Email</a>
      </div>
      <div className={"copyright"}>
        Copyright &#169; Mark Beeson.
      </div>
    </footer>

  </div>
)

export const query = graphql`
  query($slug: String!, $date: Date, $id: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      timeToRead
      frontmatter {
        title
        subhead
        cover {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        covercaption
      }
    },
    olderpost: allMarkdownRemark(filter: { frontmatter: { date: { lt: $date } } }, sort: { order: DESC, fields: [ frontmatter___date ]}, limit: 1) {
      edges {
        node {
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date
            datestr: date(formatString: "ddd MMM D, YYYY")
            subhead
            cover {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }      
    },
    newerpost: allMarkdownRemark(filter: { frontmatter: { date: { gt: $date } } }, sort: { order: ASC, fields: [ frontmatter___date ]}, limit: 1) {
      edges {
        node {
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date
            datestr: date(formatString: "ddd MMM D, YYYY")
            subhead
            cover {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }      
    }, 
    latestposts: allMarkdownRemark(filter: { id: { ne: $id } }, sort: { order: DESC, fields: [ frontmatter___date ]}, limit: 4) {
      edges {
        node {
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date
            datestr: date(formatString: "ddd MMM D, YYYY")
            subhead
            cover {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }      
    }   
  }
`

export default ArticlePage
